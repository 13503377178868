import { useField, useFormikContext } from 'formik';

export function useCustomField<T>(name: string, autoSubmit = false) {
  const [field] = useField<T>(name);
  const { errors, handleBlur, setFieldValue, submitForm, touched } = useFormikContext();

  const onChange = (item: T) => {
    setFieldValue(name, item, true);
    if (autoSubmit) setTimeout(() => submitForm(), 0);
  };

  return {
    value: field.value,
    errors,
    handleBlur,
    setFieldValue: onChange,
    touched,
  };
}
